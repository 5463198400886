import AxiosControl from '../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getBalance = (startDate, endDate) => async (dispatch) => {
    dispatch({
        type: 'BALANCE_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/balance/getbalance/', {
            headers: { auth: localStorage.getItem('jwt') },
            params: {
                startDate: startDate ? new Date(startDate).toISOString().split('T')[0] : undefined,
                endDate: endDate ? new Date(endDate).toISOString().split('T')[0] : undefined,
            }
        })
        if (status === 200) {
            dispatch({
                type: 'BALANCE_GET_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'BALANCE_NOT_LOADING'
            })
        }
    } catch (error) {
        dispatch({
            type: 'BALANCE_NOT_LOADING'
        })
    }
}