import React from 'react'
import ReactDOM from 'react-dom/client'
import './translations/i18n'
import { Provider } from 'react-redux'
import store from './store'
import App from './App'
import moment from 'moment-timezone'

moment.tz.setDefault('Europe/Madrid')


window.jwt = ''

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);