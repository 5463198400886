export const billsReducer = (state = {loading: false, resultbill: null, bills: null, bill: null, numbill: null } , action) => {
    switch (action.type) {
        case 'BILL_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                bills: action.payload.bills
            }
        case 'BILL_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                bill: action.payload.bill
            }
        case 'BILL_GET_ID_ERROR':
            return {
                ...state,
                loading: false,
                resultbill: action.payload.message
            }
        case 'BILL_ADD_SUCCESS':
            return {
                ...state,
                loading:false,
                bills: [...state.bills, action.payload.bill],
                resultbill: action.payload.message
            }
        case 'NUM_BILL_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                numbill: action.payload
            }
        case 'BILL_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                bills: state.bills.map(bill => bill._id === action.payload.bill._id ? action.payload.bill : bill),
                resultbill: action.payload.message
            }
        case 'BILL_REMOVE_RESULT':
            return {
                ...state,
                loading:false,
                resultbill: action.payload.message,
                bills: state.bills.filter(bills => bills._id !== action.payload.bill)
            }
        case 'BILL_SEND_SUCCESS':
            return {
                ...state,
                loading:false,
                resultbill: action.payload.message
            }
        case 'BILL_RESET_RESULT':
            return {...state,
                resultbill: null
            }
        case 'BILL_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'BILL_NOT_LOADING':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

