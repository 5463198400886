import AxiosControl from '../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getBills = () => async (dispatch) => {
    dispatch({
        type: 'BILL_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/bill/getall/', {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'BILL_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'BILL_NOT_LOADING'
        })
    }
}

export const getBill = (bill) => async (dispatch) => {
    dispatch({
        type: 'BILL_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/bill/get/'+ bill, {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'BILL_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'BILL_NOT_LOADING'
        })
    }
}

export const getBillDownload = (id,tokenbill) => async (dispatch) => {
    dispatch({
        type: 'BILL_LOADING'
    })
    try {

        const { data, status } = await Axios.get('/api/download/getbill/'+ id + '/'+ tokenbill)
        if (status === 200) {
            dispatch({
                type: 'BILL_GET_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'BILL_NOT_LOADING'
        })
    }
}

export const addBill = (billdata) => async (dispatch) => {
    try {
        const { data, status} = await Axios.post('/api/bill/register', billdata, {
                headers: { auth: localStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'BILL_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'BILL_NOT_LOADING'
        })
    }
}

export const getLastNumBill = (year) => async (dispatch) => {
    try {
        const { data, status } = await Axios.get('/api/bill/getlastnumbill', {
            headers: { auth: localStorage.getItem('jwt') },
            params: {year}
        })
        if (status === 200) {
            dispatch({
                type: 'NUM_BILL_GET_SUCCESS',
                payload: data.numbill
            })
        }
    } catch (error) {
        dispatch({
            type: 'BILL_NOT_LOADING'
        })
    }
}


export const editBill = (billdata, idBill) => async (dispatch) => {
    try {
        const { data, status} = await Axios.put('/api/bill/edit/' + idBill, billdata, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'BILL_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'BILL_ERROR'
        })
    }
}

export const removeBill = (bill) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/bill/remove/' + bill, {
            headers: {
                auth: localStorage.getItem('jwt')
            }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'BILL_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'BILL_NOT_LOADING'
        })
    }
}

export const sendBill = (bill) => async (dispatch) => {
    try {
        const { data, status } = await Axios.get('/api/download/emaildownloadbill/'+ bill , {
            headers: {
                auth: localStorage.getItem('jwt')
            }
        })
        if (status === 200) {
            dispatch({
                type: 'BILL_SEND_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'BILL_NOT_LOADING'
        })
    }
}