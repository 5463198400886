import { Navigate } from "react-router-dom"
import jwtDecode from "jwt-decode"

/**
 * Comprueba si el usuario está logeado
 * @returns Redirecciona al login si no está logeado y si lo está renderiza la vista pedida
 */
const PrivateAdminRouter = ({children}) => {
    const token = localStorage.getItem('jwt')
    let token_decode = null
    if (token!=='undefined'){
        token_decode = jwtDecode(token)
        if(token_decode.rol === 'Admin'){
            return children
        }else{
            return <Navigate to="/"/>
        }
    }
}

export default PrivateAdminRouter