import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams  } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { getBillDownload } from '../../../actions/billsActions'
import { PDFDownloadLink } from '@react-pdf/renderer'
import BillPDF from './BillPDF'

export default function BillDownload() {
    const {id, tokenbill} = useParams()
    const dispatch = useDispatch()
    const {bill} = useSelector(state=>state.bills)


    useEffect(() => {
        dispatch(getBillDownload(id, tokenbill))
    },[id,tokenbill])

    return (
        bill
        ?
        <Container>
             <div className='d-flex justify-content-center align-items-center mt-4'>
                <img src="/images/logo-reagra.png" alt="logotipo" className='logo' width={150}></img>
                {/* <ChangeLanguage /> */}
            </div>
            <Row>
                <Col xs={12} lg={6} className='mt-5 mx-auto'>
                    <h1 className='text-center'>FACTURA {new Date(bill.date).getFullYear()}/{bill?.numBill}</h1>
                    <p className='text-center my-3'>Descarque aquí su factura</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={4} className='mx-auto'>
                    <PDFDownloadLink document={<BillPDF bill={bill}/>} fileName={`${new Date(bill?.date).getFullYear()}_${bill?.numBill}.pdf`}>
                        <div className='btn btn-primary mx-2'>
                            <i className="fas fa-file-pdf"></i> Descargar
                        </div>
                    </PDFDownloadLink>
                </Col>
            </Row>
        </Container>

        :
        <h1 className='text-center'>LA FACTURA NO EXISTE</h1>
    )
}