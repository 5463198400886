import React, { useEffect, useState } from 'react'
import {Container,Row,Col,Button,Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from "../../context/auth/useAuth"
import { Link, useNavigate } from 'react-router-dom'
import ChangeLanguage from '../../translations/ChangeLanguage'

const clientCredentials = {}

export default function Login() {
    const { t } = useTranslation()
    const [islogging, setIslogging] = useState(false)
    const [isCorrect, setIsCorrect] = useState(true)
    const {loginClient, client} = useAuth()
    const navigate = useNavigate()

    const [showPasswordOne, setShowPasswordOne] = useState(false)

    const handlePasswordVisibilityOne = () => {
        setShowPasswordOne(!showPasswordOne)
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        setIslogging(true)
        loginClient(clientCredentials).then(() => {
            setIsCorrect(false)
            setIslogging(false)
        })
    }

    useEffect(() => {
        if (client) {
            switch (client.rol) {
                case "Admin":
                    navigate("/admin/dashboard")
                    break;
                default:
                    break;
            }
        }
    }, [client, navigate])

    return (
        <>
            <div className='d-flex justify-content-center align-items-center mt-4'>
                <img src="/images/logo-reagra.png" alt="logotipo" className='logo' width={150}></img>
                {/* <ChangeLanguage /> */}
            </div>
            <Container className='mt-5 px-5 form-login'>
                <Row>
                    <Col className='text-center'>
                        <h1>{t('signin.welcome')}</h1>
                        <p>{t('signin.frase')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="px-5">
                        <Form className="formulario" onSubmit={ handleSubmit}>
                            <Row className="my-3">
                                {isCorrect
                                    ?
                                        <Form.Control placeholder={t('signin.emailplace')} name="login_email" className="sd-input" type="text" autoComplete="off" onChange={ (e) => clientCredentials.email = e.target.value} />
                                    :
                                        <Form.Control placeholder={t('signin.emailplace')} name="login_email" className="sd-input sd-input-wrong" type="text" autoComplete="off" onChange={ (e) => clientCredentials.email = e.target.value} />
                                }
                            </Row>
                            <Row className="text-center my-3 row-eye">
                                {isCorrect
                                    ?
                                        <>
                                            <Form.Control placeholder={t('signin.passwordplace')}  name="login_password" className="sd-input" type={showPasswordOne ? 'text' : 'password'} autoComplete="off" onChange={ (e) => clientCredentials.password = e.target.value} />
                                            <img className="sd-imageneye" src={showPasswordOne ? "../images/eyeclose.svg" : "../images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityOne}></img>
                                        </>
                                    :
                                        <>
                                            <Form.Control placeholder={t('signin.passwordplace')}  name="login_password" className="sd-input sd-input-wrong" type={showPasswordOne ? 'text' : 'password'} autoComplete="off" onChange={ (e) => clientCredentials.password = e.target.value} />
                                            <img className="sd-imageneye" src={showPasswordOne ? "../images/eyeclose.svg" : "../images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityOne}></img>
                                        </>
                                }
                            </Row>
                            {!isCorrect
                                ?
                                    <>
                                        <span>{t('signin.loginwrong')}</span>
                                    </>
                                :
                                    <></>
                            }
                            <Row className="text-center my-3">
                                <Col>
                                    <Button variant='primary' type="submit" className="w-100 my-2">
                                    {islogging
                                        ?
                                            <>
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </>
                                        :
                                            <>{t('signin.login')}</>
                                    }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col className="justify-content-center text-center">
                        {t('signin.passwordforgoten')}
                        <Link to="/forgotpassword" className="link-underline">{t('signin.forgotpassword')}</Link>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center mt-3'>
                        <small>v.1.0.0</small>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
