import AxiosControl from '../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getLogs = () => async (dispatch) => {
    dispatch({
        type: 'LOG_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/log/getall/', {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if(status===200){
            dispatch({
                type: 'LOG_GET_SUCCESS',
                payload: data.logs
            })
        }
    } catch (error) {
        dispatch({ type: 'LOG_ERROR' })
    }
}