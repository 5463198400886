import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { addMovement, getMovements,getMovement, editMovement, removeMovement } from '../../../actions/movementsActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import Swal from 'sweetalert2'
import Loader from '../../../components/loaders/Loader'
import { getProviders } from '../../../actions/providersActions'
import MovementForm from '../../../components/forms/MovementForm'

export default function Movements() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {movements, movement, resultmovement, loading} = useSelector(state=>state.movements)
    const {providers} = useSelector(state=>state.providers)

    const [modalShow, setModalShow] = useState(false)
    const [formState, setFormState] = useState({})
    const [isRegistering, setIsRegistering] = useState(false)
    const [isAddingOrEditing, setIsAddingOrEditing] = useState(false)

    useEffect(() => {
        if(client){
            dispatch(getMovements())
            dispatch(getProviders())
        }
    },[dispatch, t, client])

    const openModal = () =>{
        setIsAddingOrEditing('adding')
        setFormState({
            provider: null,
            date: new Date(),
            nature: 'UCO SOSTENIBLE',
            quantity: 0,
            typeMove: 'in'
        })
        setModalShow(true)
    }

    const selectMovement = (movement) => {
        dispatch(getMovement(movement._id))
        setIsAddingOrEditing('editing')
        setFormState(movement)
        setModalShow(true)
    }

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleSelectChange = (element, attribute)=>{
        if(attribute === 'provider'){
            setFormState({
                ...formState,
                [attribute]: element.value
            })
        }else{
            setFormState({
                ...formState,
                [element.target.name]: element.target.value
            })
        }
    }

    useEffect(()=>{
        if (movement) {
            setFormState({
                ...movement,
                provider: movement.provider._id
            })
        }
    }, [movement, client, dispatch])

    const handleSubmit = async(e) => {
        e.preventDefault()
        setIsRegistering(true)
        if(isAddingOrEditing==='editing'){
            dispatch(editMovement(formState, formState._id))
        }else{
            dispatch(addMovement(formState))
        }
        setIsRegistering(false)
    }

    const handleDeleteMovements = async(e, movementId) => {
        e.preventDefault()
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            html: t('admin.movements.areyousureremove')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeMovement(movementId))
                setModalShow(false)
            }
        })
    }

    useEffect(() => {
        if(resultmovement){
            let resultmovementTemp = resultmovement
            dispatch({ type: 'MOVEMENT_RESET_RESULT'})
            switch (resultmovementTemp) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.movements.addsuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break
                default:
                    break
            }
        }
    // eslint-disable-next-line
    }, [resultmovement])

    const columnsMovements = [
        {
            name: t('admin.movements.date'),
            width: '180px',
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {new Date(movement.date).toLocaleString()}
                </div>,
            selector: (movement) => movement.date,
            sortable: true,
        },{
            name: t('admin.movements.billed'),
            width: '100px',
            center: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.isBilled ? <i className='fas fa-check'></i> : ''}
                </div>,
            selector: (movement) => movement.isBilled,
            sortable: true,
        },{
            name: t('admin.movements.provider'),
            left: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.provider?.identifier} - {movement.provider?.name}
                </div>,
            selector: (movement) => `${movement.provider?.identifier} - ${movement.provider?.name}`,
            sortable: true,
        },{
            name: t('admin.movements.document'),
            width: '180px',
            center: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.document}
                </div>,
            selector: (movement) => movement.document,
            sortable: true,
        },{
            name: t('admin.movements.quantity'),
            width: '120px',
            right: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.quantity} KG
                </div>,
            selector: (movement) => movement.quantity,
            sortable: true,
        },{
            name: t('admin.movements.nature'),
            width: '150px',
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.nature}
                </div>,
            selector: (movement) => movement.nature,
            sortable: true,
        },{
            name: t('admin.movements.typeMove'),
            width: '100px',
            center: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {t('admin.movements.typeMove'+movement.typeMove)}
                </div>,
            selector: (movement) => movement.typeMove,
            sortable: true,
        },{
            name: '',
            width: '80px',
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    <i className='fas fa-pencil'></i>
                </div>,
            sortable: false,
        }
    ]

    return (
        <Container className='mt-5 px-0'>
            <Row>
                <Col className='text-center'>
                    <h1>{t('admin.movements.movements')}</h1>
                </Col>
                <Col xs={12} className='d-flex justify-content-end'>
                    <Button variant='primary' type="submit" className="my-2 cursor" onClick={() => openModal()}>
                        {t('admin.movements.createmovement')}
                    </Button>
                </Col>
            </Row>

            {
                loading
                    ?
                        <Loader/>
                    :

                        <Row>
                            <Col>
                            {movements?.length > 0
                                ?
                                    <CustomDataTable columns={columnsMovements} data={movements} exportable={false} printable={false}/>
                                :
                                    <h4 className='mt-4'>{t('admin.movements.thereisnotmovements')}</h4>
                            }
                            </Col>
                        </Row>

            }
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>{t('admin.movements.movements')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MovementForm
                        state={formState}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        handleDeleteMovements={handleDeleteMovements}
                        providers={providers}
                        submit={handleSubmit}
                        isAddingOrEditing={isAddingOrEditing}
                        isRegistering={isRegistering}
                    />
                </Modal.Body>
            </Modal>
        </Container>
    )
}