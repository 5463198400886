import { Route, Routes } from 'react-router-dom'
import {Container} from 'react-bootstrap'
import NotFoundPage from '../pages/error/404'
import { Forgot} from '../pages/auth/Forgot'
import Login from '../pages/auth/Login'
import ResetPassword from '../pages/auth/ResetPassword'
import { ResetPasswordSuccess } from '../pages/auth/ResetPasswordSuccess'
import { EmailSend } from '../pages/auth/EmailSend'
import BillDownload from '../pages/admin/bills/BillDownload'

export default function AuthRouter () {
    return (
        <Container className="contenido-auth">
            <Routes>
                <Route path="/" element={<Login />}/>
                <Route path="/login" element={<Login />}/>
                <Route path="/forgotpassword" element={<Forgot />}/>
                <Route path="/emailsend/:email" element={<EmailSend />}/>
                <Route path="/resetpassword/:id/:tokenresetpassword" element={<ResetPassword />}/>
                <Route path="/resetpasswordsuccess" element={<ResetPasswordSuccess />}/>
                <Route path="/download/bill/:id/:tokenbill" element={<BillDownload />}/>
                <Route path="*" element={<NotFoundPage />}/>
            </Routes>
        </Container>
    )
}