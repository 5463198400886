export const providersReducer = (state = {loading: true, resultprovider: null, providers: null, provider: null } , action) => {
    switch (action.type) {
        case 'PROVIDER_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                providers: action.payload.providers
            }
        case 'PROVIDER_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                provider: action.payload
            }
        case 'PROVIDER_ADD_SUCCESS':
            return {
                ...state,
                loading:false,
                providers: [...state.providers, action.payload.provider],
                resultprovider: action.payload.message
            }
        case 'PROVIDER_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                providers: state.providers.map(provider => provider._id === action.payload.provider._id ? action.payload.provider : provider),
                resultprovider: action.payload.message
            }
        case 'PROVIDER_ENABLEDISABLE_SUCCESS':
            console.log('Rducer',action.payload.message)
            return {
                ...state,
                loading:false,
                provider: action.payload.provider,                
                providers: state.providers.map(provider => provider._id === action.payload.provider._id ? action.payload.provider : provider),
                resultprovider: action.payload.message
            }
        case 'PROVIDER_RESET_RESULT':
            return {...state,
                resultprovider: null
            }
        case 'PROVIDER_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'PROVIDER_NOT_LOADING':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

