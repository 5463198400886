import React, {useState} from 'react'
import axios from 'axios'
import { useNavigate, useParams  } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'

const ResetPassword = (props) => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [isCorrect, setIsCorrect] = useState("")
    const [password, setPassword] = useState("")

    const { id } = useParams()
    const { tokenresetpassword } = useParams()

    /**
     * Captura el submit del formulario y cambia la clave de la cuenta
     * @param {*} e 
     */
    const handleSubmit = async(e) => {
        e.preventDefault()

        const clientPassword = {
            password: e.target.password.value
        }

        setIsLoading(true)

        await axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/forgot/resetpassword?id=' + id + '&token=' + tokenresetpassword, clientPassword, {
            where: {
                id: id,
                tokenresetpassword: tokenresetpassword
            }
        })
        .then((res) => {            
            setIsLoading(false)
            navigate('/resetpasswordsuccess')
        }).catch((err) => {
            setIsLoading(false)
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: 'Ha habido un error al intentar enviar los datos, vuelva a intentarlo más tarde'
            })
        })
    }

    const checkValidation = (e) => {
        const confirmPass = e.target.value

        if (password !== confirmPass) {
            setIsCorrect("Las contraseñas no coinciden")
        }else {
            setIsCorrect("")
        }
    }

    return (
        <>
            <div className='d-flex justify-content-center align-items-center mt-4'>
                <img src="/images/logo-reagra.png" alt="logotipo" className='logo' width={150}></img>
                {/* <ChangeLanguage /> */}
            </div>
            <Container className='mt-5 px-5'>
                <Row>
                    <Col className="d-flex justify-content-center text-center">
                        <Form className="p-4 formulario" onSubmit={handleSubmit}>
                            <Row>
                                <h1>{t('passwordreset.question')}</h1>
                                <Form.Label className="form-label">{t('passwordreset.help')}</Form.Label>
                            </Row>
                            <Row className="text-center my-3">
                                {isCorrect === ''
                                    ?
                                        <Form.Control placeholder={t('passwordreset.new_password')} name="password" className="sd-input" type="text" autoComplete="off" onChange={(e) => setPassword(e.target.value)}/>
                                    :
                                        <Form.Control placeholder={t('passwordreset.new_password')} name="password" className="sd-input sd-input-wrong" type="text" autoComplete="off" onChange={(e) => setPassword(e.target.value)}/>
                                }
                            </Row>
                            <Row className="text-center my-3">
                                {isCorrect === ''
                                    ?
                                        <Form.Control placeholder={t('passwordreset.confirm_password')} name="confirm_password" className="sd-input" type="text" autoComplete="off" onChange={checkValidation}/>
                                    :
                                        <Form.Control placeholder={t('passwordreset.confirm_password')} name="confirm_password" className="sd-input sd-input-wrong" type="text" autoComplete="off" onChange={checkValidation}/>
                                }
                            </Row>
                            {isCorrect !== ''
                                ?
                                    <>
                                        <span>{t('passwordreset.passwordsdoesnotmatch')} *</span>
                                    </>
                                :
                                    <></>
                            }
                            <Row className="text-center my-3">
                                <Button type="submit" variant="primary" className="w-100 my-2">
                                    {isLoading
                                        ?
                                            <span className="indicator-label">
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        :
                                            <span className="indicator-label">{t('passwordreset.submit')}</span>
                                    }
                                </Button>
                            </Row>
                            <Row>
                                <Col>
                                    <Link className="btn btn-secondary px-3" to="/login">
                                        <i className='fas fa-chevron-left'></i> {t('global.return')}
                                    </Link>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ResetPassword