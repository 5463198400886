import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { getUser } from '../../actions/usersActions'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../context/auth/useAuth'


export default function NotFoundPage () {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {client} = useAuth()
    const {user} = useSelector(state=>state.users)

    useEffect(() => {
        if(client){
            dispatch(getUser(client?.id))
        }
    },[dispatch, client])

	return (
        <>       
            <Container className='mt-5 px-5'>
				<Row>
					<Col className='text-center'>
						<h1>{t('global.pagenotfound')}</h1>
					</Col>
				</Row>
				<Row className='mt-3'>
					<Col className='text-center'>
						<h4>{t('global.error404description')}</h4>
						<h4>{t('global.error404')}</h4>
					</Col>
				</Row>
				<Row className='mt-5'>
					<Col className='d-flex justify-content-center'>
						<Link to={user?.role === 'Admin' ? '/admin/dashboard' : '/'} className='btn btn-secondary w-25'>Inicio</Link>
					</Col>
            	</Row>
			</Container>
		</>
	)
}