import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFoundPage from '../pages/error/404'
import {Container, Row, Col} from 'react-bootstrap'
import MenuAdmin from '../components/menus/MenuAdmin'
import Dashboard from '../pages/admin/Dashboard'
import Providers from '../pages/admin/providers/Providers'
import Movements from '../pages/admin/movements/Movements'
import Balance from '../pages/admin/balances/Balance'
import Logs from '../pages/admin/Logs'
import Bills from '../pages/admin/bills/Bills'
import Version from '../version'

export default function AdminRouter () {
	return (
		<Container className='contenido-admin'>
			<MenuAdmin/>
			<Routes>
				<Route path="/dashboard" element={<Dashboard/>}/>
				<Route path="/providers" element={<Providers/>}/>
				<Route path="/movements" element={<Movements/>}/>
				<Route path="/balance" element={<Balance/>}/>
				<Route path="/bills" element={<Bills/>}/>
				<Route path="/logs" element={<Logs/>}/>
				<Route path="*" element={<NotFoundPage />}/>
			</Routes>
			<Row>
                <Col className='text-center mt-2'>
                    <Version />
                </Col>
            </Row>
		</Container>
	)
}