import AxiosControl from '../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getUsers = () => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/user/getall/', {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'USER_GETALL_SUCCESS',
                payload: data.users
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const getUser = (user) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/user/get/'+ user, {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'USER_GET_SUCCESS',
                payload: data.user
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const addUser = (userdata) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/login/register', userdata, {
                headers: { auth: localStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_ADD_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'USER_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const activeUser = (user, codVerification) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/login/activate/' + user, {activate_code: codVerification}, {
                headers: { auth: localStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'USER_EDIT_SUCCESS',
                payload: data.message
            })
            dispatch({
                type: 'USER_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const sendVerificationCode = (user) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/user/sendverificationcode/' + user, {}, {
                headers: { auth: localStorage.getItem('jwt') }
            }
        )
        if (status === 200) {
            dispatch({
                type: 'USER_EMAILSEND',
                payload: data.message
            })
            dispatch({
                type: 'USER_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}

export const editUser = (userdata, idUser) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/user/edit/' + idUser, userdata, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'USER_EDIT_SUCCESS',
                payload: data.message
            })
            dispatch({
                type: 'USER_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_ERROR'
        })
    }
}

export const enableUser = (idUser) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/user/enable/' + idUser, {}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'USER_ENABLEDISABLE_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'USER_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_ERROR'
        })
    }
}

export const disableUser = (idUser) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/user/disable/' + idUser, {}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 263 || status === 260) {
            dispatch({
                type: 'USER_ENABLEDISABLE_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'USER_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_ERROR'
        })
    }
}

export const removeUser = (userId) => async (dispatch) => {
    dispatch({
        type: 'USER_LOADING'
    })
    try {
        const { status } = await Axios.delete('/api/user/remove/' + userId, {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'USER_REMOVE_SUCCESS'
            })
        }
    } catch (error) {
        dispatch({
            type: 'USER_NOT_LOADING'
        })
    }
}