import AxiosControl from '../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getProviders = () => async (dispatch) => {
    dispatch({
        type: 'PROVIDER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/provider/getall/', {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PROVIDER_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_NOT_LOADING'
        })
    }
}

export const getProvider = (provider) => async (dispatch) => {
    dispatch({
        type: 'PROVIDER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/provider/get/'+ provider, {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PROVIDER_GET_SUCCESS',
                payload: data.provider
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_NOT_LOADING'
        })
    }
}

export const addProvider = (providerdata) => async (dispatch) => {
    dispatch({
        type: 'PROVIDER_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/provider/register', providerdata, {
                headers: { auth: localStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'PROVIDER_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_NOT_LOADING'
        })
    }
}

export const editProvider = (providerdata, idProvider) => async (dispatch) => {

    try {
        const { data, status} = await Axios.put('/api/provider/edit/' + idProvider, providerdata, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'PROVIDER_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_ERROR'
        })
    }
}

export const enableProvider = (idProvider) => async (dispatch) => {
    try {
        const { data, status} = await Axios.put('/api/provider/enable/' + idProvider, {}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'PROVIDER_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_ERROR'
        })
    }
}

export const disableProvider = (idProvider) => async (dispatch) => {
    try {
        const { data, status} = await Axios.put('/api/provider/disable/' + idProvider, {}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 263 || status === 260) {
            dispatch({
                type: 'PROVIDER_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_ERROR'
        })
    }
}