
import { Button, Col, Form, Row} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Select from "react-select"

export default function MovementForm({state, handleInputChange, handleSelectChange, submit, isAddingOrEditing, isRegistering, handleDeleteMovements, providers}) {
    const { t } = useTranslation()
    return (
        <Form  onSubmit={submit} className="px-4 formulario">
            <Row>
                <Col xs={12} md={8} className="my-2">
                    <Form.Label>{t('admin.movements.provider')}</Form.Label>
                    <Select
                        className="sd-input"
                        name='provider'
                        value={state?.provider ? {value: state?.provider, label: providers?.find(provider => provider._id === state?.provider)?.name} : ''}
                        onChange={(e) => handleSelectChange(e, 'provider')}
                        options={[
                            ...(providers?.map(provider => ({value: provider._id, label: provider.name})) || [])
                        ]}
                        required
                    />
                </Col>
                <Col xs={12} md={4} className="my-2">
                    <Form.Label>{t('admin.movements.typeMove')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        as="select"
                        name='typeMove'
                        value={state.typeMove || 'in'}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value="in">{t('admin.movements.typeMovein')}</option>
                        <option value="out">{t('admin.movements.typeMoveout')}</option>
                        <option value="waste">{t('admin.movements.typeMovewaste')}</option>
                    </Form.Control>
                </Col>
            </Row>
            <Row>
                <Col className="my-2">
                    <Form.Label>{t('admin.movements.date')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='date'
                        name='date'
                        value={state.date ? new Date(state.date).toISOString().split('T')[0] : ''}
                        onChange={handleInputChange}
                        required
                    />
                </Col>
                <Col className="my-2">
                    <Form.Label>{t('admin.movements.nature')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        as="select"
                        name='nature'
                        value={state.nature || 'UCO SOSTENIBLE'}
                        onChange={handleSelectChange}
                        required
                    >
                        <option value="UCO SOSTENIBLE">{t('admin.movements.typeNatureS')}</option>
                        <option value="UCO NO SOSTENIBLE">{t('admin.movements.typeNatureNS')}</option>
                    </Form.Control>
                </Col>
                <Col className="my-2">
                    <Form.Label>{t('admin.movements.quantity')} (KG)</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='number'
                        name='quantity'
                        value={state.quantity || 0}
                        onChange={handleInputChange}
                        required
                    />
                </Col>
                <Col className="my-2">
                    <Form.Label>{t('admin.movements.document')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='document'
                        value={state.document || ''}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} className="my-2 ms-auto">
                    <Button variant='primary' type="submit" className="w-100 my-2">
                    {isRegistering
                        ?
                            <>
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </>
                        :
                            isAddingOrEditing === 'editing'
                            ?
                                <>{t('global.edit')}</>
                            :
                                <>{t('global.register')}</>
                    }
                    </Button>
                    {isAddingOrEditing === 'editing' && (
                        <Button variant="fourth" className="w-100 my-2 cursor" onClick={(e) => handleDeleteMovements(e, state?._id)}>
                            {t('global.delete')}
                        </Button>
                    )}

                </Col>
            </Row>
        </Form>
    )
}