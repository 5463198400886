import { createContext, useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import Axios from 'axios'
import { useDispatch } from "react-redux"

export const AuthContext = createContext()

export default function AuthProvider({children}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let token = localStorage.getItem('jwt')

    const [client, setClient] = useState()

    /**
     * Loguea un usuario y genera el token
     * @param {*} clientCredentials
     */
    const loginClient = async (clientCredentials) => {
        const options = {
            headers: { "Access-Control-Allow-Origin": "*" },
        }
        const result = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/login/in', { email: clientCredentials.email, password: clientCredentials.password }, options)
        localStorage.setItem('jwt', result.data['token'])
        token = localStorage.getItem('jwt')

        if (token!=='undefined') {
            setClient(jwtDecode(token))
            let rol = jwtDecode(token).rol

            if(rol === 'Admin'){
                navigate('/admin/dashboard')
            }else{
                navigate('/')
            }
        }
    }

    // Desloguea un usuario eliminando token y seteando el usuario a null
    const logout = () => {
        setClient(null)
        dispatch({ type: 'USER_LOGOUT'})
        localStorage.removeItem('jwt')
        navigate('/')
    }

    // Comprueba el estado del usuario y devuelve true o false
    const isLogged = () => !!client

    // Variables accesibles desde cualquier componente
    const contextValue = {client, isLogged, loginClient, logout}

    /**
     * Usamos el useEffect para comprobar que el token no ha cambiado
     */
    // token = localStorage.getItem('jwt')
    useEffect(() =>{
        try {
            setClient(jwtDecode(token))
        } catch (error) {
            setClient(null)
            localStorage.removeItem('jwt')
        }
    }, [token])

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}
