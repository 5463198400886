export const balancesReducer = (state = {loading: true, balance: null, initialBalance: null } , action) => {
    switch (action.type) {
        case 'BALANCE_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                balance: action.payload.balance,
                initialBalance: action.payload.initialBalance
            }
        case 'BALANCE_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'BALANCE_NOT_LOADING':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}