import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { addProvider, getProviders, editProvider, enableProvider, disableProvider } from '../../../actions/providersActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import Swal from 'sweetalert2'
import Loader from '../../../components/loaders/Loader'
import ProviderForm from '../../../components/forms/ProviderForm'

export default function Providers() { 
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {providers, resultprovider, loading} = useSelector(state=>state.providers)

    const [modalShow, setModalShow] = useState(false)
    const [formState, setFormState] = useState({})
    const [isRegistering, setIsRegistering] = useState(false)
    const [isAddingOrEditing, setIsAddingOrEditing] = useState(false)
    const [filterActive, setFilterActive] = useState(true)

    useEffect(() => {
        if(client){
            dispatch(getProviders())
        }
    },[dispatch, t, client])

    const openModal = () =>{
        setIsAddingOrEditing('adding')
        setFormState({
            identifier: '',
            name: '',
            companyName: '',
            contact: '',
            address: '',
            cp: '',
            location: '',
            province: '',
            phone: '',
            cif: '',
            iscc: false,
            others: '',
        })
        setModalShow(true)
    }

    const selectProvider = (provider) => {
        setIsAddingOrEditing('editing')
        setFormState(provider)
        setModalShow(true)
    }

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.checked
        })
    }

    const handleSubmit= async(e) => {
        e.preventDefault()
        setIsRegistering(true)

        if(isAddingOrEditing==='editing'){
            dispatch(editProvider(formState, formState._id))
        }else{
            dispatch(addProvider(formState))
        }
        setIsRegistering(false)
    }

    const disableEnableProviders = async(e) => {
        e.preventDefault()
        let textQuestion = ''
        formState.active
            ?
                textQuestion = t('admin.providers.areyousuredisable')+'<br><p><label className="fs-20 fw-600 pt-5">'+formState?.name+'</label></p>'
            :
                textQuestion = t('admin.providers.areyousureenable')+'<br><p><label className="fs-20 fw-600 pt-5">'+formState?.name+'</label></p>'

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            html: textQuestion
        }).then((result) => {
            if (result.isConfirmed) {
                if (formState.active) {
                    dispatch(disableProvider(formState._id))
                }else{
                    dispatch(enableProvider(formState._id))
                }
            }
        })
    }

    useEffect(() => {
        if(resultprovider){
            let resultproviderTemp = resultprovider
            dispatch({ type: 'PROVIDER_RESET_RESULT'})
            switch (resultproviderTemp) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.providers.addsuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break;
                case 'successedit':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.providers.editsuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break;
                case 'successenable':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.providers.enablesuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break;
                case 'successdisable':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.providers.disablesuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultprovider])

    const columnsProviders = [
        {
            name: t('admin.providers.identifier'),
            cell: provider =>
                <div className='cursor' onClick={() => selectProvider(provider)}>
                    {provider.identifier}
                </div>,
            selector: (provider) => provider.identifier,
            sortable: true,
        },{
            name: t('admin.providers.name'),
            width: '30%',
            cell: provider =>
                <div className='cursor' onClick={() => selectProvider(provider)}>
                    {provider.name}
                </div>,
            selector: (provider) => provider.name,
            sortable: true,
        },{
            name: t('admin.providers.location'),
            width: '20%',
            cell: provider =>
                <div className='cursor' onClick={() => selectProvider(provider)}>
                    {provider.location} {provider.province ? <>({provider.province})</> : ''}
                </div>,
            selector: (provider) => provider.location,
            sortable: true,
        },{
            name: t('admin.providers.phone'),
            width: '10%',
            cell: provider =>
                <div className='cursor' onClick={() => selectProvider(provider)}>
                    {provider.phone}
                </div>,
            selector: (provider) => provider.phone,
            sortable: true,
        },{
            name: t('admin.providers.iscc'),
            width: '100px',
            cell: provider =>
                <div>
                    {provider.iscc
                        ?
                            <img src="../images/check.svg" alt="check" width={25}></img>
                        :
                            <img src="../images/times.svg" alt="times" width={25}></img>
                    }
                </div>,
            selector: (provider) => provider.iscc,
            sortable: true,
        },{
            name: t('admin.providers.active'),
            width: '100px',
            cell: provider =>
                <div>
                    {provider.active
                        ?
                            <img src="../images/check.svg" alt="check" width={25}></img>
                        :
                            <img src="../images/times.svg" alt="times" width={25}></img>
                    }
                </div>,
            selector: (provider) => provider.active,
            sortable: true,
        },{
            name: '',
            width: '100px',
            cell: provider =>
                <div className='cursor' onClick={() => selectProvider(provider)}>
                    <i className='fas fa-pencil'></i>
                </div>,
            sortable: false,
        }
    ]
    const ExpandedRow = ({ data }) => {
        return (
            <Container className='p-3'>
                <Row>
                    <Col xs={12} lg={6}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.providers.cif')}</small> {data?.cif}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.providers.companyName')}</small> {data?.companyName}
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.providers.contact')}</small> {data?.contact}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.providers.address')}</small> {data?.address}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.providers.cp')}</small> {data?.cp}
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    const filteredProviders = providers?.filter(provider => provider.active === filterActive)

    return (
        <Container className='mt-5 px-0'>
            <Row>
                <Col xs={12} className='text-center'>
                    <h1>
                        {filterActive
                            ?
                            t('admin.providers.providersenabled')
                            :
                            t('admin.providers.providersdisabled')
                        }
                    </h1>
                </Col>
                <Col xs={6} className='d-flex justify-content-start'>
                    <ToggleButtonGroup
                        type="radio"
                        name="filterActive"
                        value={filterActive}
                        onChange={setFilterActive}
                    >
                        <ToggleButton id="tbg-active" value={true} variant="outline-success">
                            {t('admin.providers.enabled')}
                        </ToggleButton>
                        <ToggleButton id="tbg-inactive" value={false} variant="outline-danger">
                            {t('admin.providers.disabled')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Col>
                <Col xs={6} className='d-flex justify-content-end'>
                    <Button variant='primary' type="submit" className="my-2 cursor" onClick={() => openModal()}>
                        {t('admin.providers.createprovider')}
                    </Button>
                </Col>
            </Row>
            {
                loading
                    ?
                        <Loader/>
                    :

                        <Row>
                            <Col>
                            {filteredProviders?.length > 0
                                ?
                                    <CustomDataTable
                                        columns={columnsProviders}
                                        data={filteredProviders}
                                        exportable={false}
                                        printable={false}
                                        expandedData={ExpandedRow}
                                    />
                                :
                                    <h4 className='mt-4'>{t('admin.providers.thereisnotproviders')}</h4>
                            }
                            </Col>
                        </Row>

            }
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>{t('admin.providers.providers')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProviderForm
                        state={formState}
                        handleInputChange={handleInputChange}
                        handleCheckboxChange={handleCheckboxChange}
                        submit={handleSubmit}
                        disableEnableProviders={disableEnableProviders}
                        isAddingOrEditing={isAddingOrEditing}
                        isRegistering={isRegistering}
                    />
                </Modal.Body>
            </Modal>
        </Container>
    )
}