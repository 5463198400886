import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { Link } from 'react-router-dom'

export default function Dashboard() { 
  	const { t } = useTranslation()
	const {client} = useAuth()    

	return (
        <Container className='my-5 p-5'>
            <Row>
                <Col>
                    <h1>{client?.username}, {t('admin.dashboard.welcome')}</h1>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col>
                    <Link to="/admin/providers">
                        <Card>
                            <Card.Body className='py-5 text-center'>
                                <h3>{t('admin.providers.title')}</h3>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to="/admin/movements">
                        <Card>
                            <Card.Body className='py-5 text-center'>
                                <h3>{t('admin.movements.title')}</h3>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to="/admin/balance">
                        <Card>
                            <Card.Body className='py-5 text-center'>
                                <h3>{t('admin.movements.balance')}</h3>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to="/admin/bills">
                        <Card>
                            <Card.Body className='py-5 text-center'>
                                <h3>{t('admin.bills.title')}</h3>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </Container>
	)
}