import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { providersReducer } from './reducers/providersReducer'
import { movementsReducer } from './reducers/movementsReducer'
import { balancesReducer } from './reducers/balancesReducer'
import { billsReducer } from './reducers/billsReducer'
import { logsReducer } from './reducers/logsReducer'

const intialState = {}
const appReducer = combineReducers({
    providers: providersReducer,
    movements: movementsReducer,
    balances: balancesReducer,
    bills: billsReducer,
    logs: logsReducer
})

const reducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

const composeEnhancer = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, intialState, composeEnhancer(applyMiddleware(thunk)))

export default store